<template>
  <div>
    <b-overlay :show="loading">
      <BookSlider class="mb-4" title="My Favorites" :books="favoriteBooks" v-if="favoriteBooks.length" />
      <BookSlider class="mb-4" v-for="cat in categories" :key="cat.id" :title="cat.title" :books="cat.books"
        :titleLink="{name:'book-cat', params:{cat_id: cat.id}}" />
    </b-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BookSlider from '@/views/public/book/components/BookSlider.vue';

export default {
  name: 'BookCategoryList',
  components: { BookSlider },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState('book', ['categories', 'favoriteBooks']),
  },

  methods: {
    getCategories() {
      this.loading = true;
      this.$store.dispatch('book/getAllCategories').catch(() => {}).finally(() => {
        this.loading = false;
      });
    },
  },

  created() {
    if (!this.categories || (this.categories.length === 0)) this.getCategories();
  },

};
</script>

<style lang="stylus">

</style>
